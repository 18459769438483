<template>
  <div>
    <div style="padding: 5px 0">
      <el-input
        v-model="search.studentNum"
        @keyup.enter.native="load"
        style="width: 250px; margin-right: 15px"
        placeholder="请输入学生学号并按回车搜索"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i
      ></el-input>
      <el-input
        v-model="search.studentName"
        @keyup.enter.native="load"
        style="width: 250px"
        placeholder="请输入学生学生姓名并按回车搜索"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i
      ></el-input>
      <el-button
        @click="load"
        type="primary"
        size="mini"
        style="margin: 10px"
        icon="el-icon-search"
        >搜索</el-button
      >
      <el-button
        @click="add"
        type="primary"
        size="mini"
        style="margin: 10px"
        icon="el-icon-plus"
        >新增</el-button
      >
      <el-button
        icon="el-icon-minus"
        type="danger"
        size="mini"
        style="margin-right: 10px"
        @click="batchDelete"
        :disabled="this.tableChecked.length === 0"
      >
        批量删除
      </el-button>
      <el-button 
      icon="el-icon-download"
      type="primary"
      size="mini"
      style="margin-right: 10px"
      @click="batchExport">
        批量导出
      </el-button>
      <!--  <el-button 
      icon="el-icon-upload2"
      type="primary"
      size="mini"
      style="margin-right: 10px"
      @click="batchImport">
        批量导入
      </el-button> -->
    </div>
    <el-table
      :data="tableData"
      border
      stripe
      style="width: 100%"
      @selection-change="handleBatchDelete"
    >
      <!-- <el-table-column prop="id" label="ID" width="100" sortable> </el-table-column> -->
      <el-table-column
        width="50"
        align="center"
        type="selection"
      ></el-table-column>
      <el-table-column
        label="序号"
        width="100"
        type="index"
        :index="indexMethod"
      ></el-table-column>
      <!-- <el-table-column prop="openid" label="微信openid"></el-table-column> -->
      <el-table-column prop="username" label="学生学号"></el-table-column>
      <!-- <el-table-column prop="password" label="密码"></el-table-column> -->
      <el-table-column prop="nickName" label="学生姓名"></el-table-column>
      <el-table-column prop="phone" label="电话号码"></el-table-column>
      <el-table-column prop="sex" label="性别">
        <template slot-scope="scope">
          <p v-if="scope.row.sex === 0">男</p>
          <p v-if="scope.row.sex === 1">女</p>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="禁用/启用" width="80">
        <template slot-scope="scope">
          <!-- <p v-if="scope.row.status == 'true'">已启用</p>
         <p v-if="scope.row.status == 'false'">已禁用</p> -->
          <el-switch
            :value="scope.row.status"
            active-value="true"
            inactive-value="false"
            disabled
          >
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column label="头像">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.img"
            :preview-src-list="[scope.row.img]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="角色">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.role"
            value-key="id"
            multiple
            placeholder="请选择"
            @change="changeRole(scope.row)"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            circle
            @click="edit(scope.row)"
          ></el-button>
          <el-popconfirm @confirm="del(scope.row.id)" title="确定删除？">
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              slot="reference"
              style="margin-left: 10px"
            >
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        :page-sizes="[10, 20, 30]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 弹窗   -->
    <el-dialog
      title="信息"
      :visible.sync="dialogFormVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="entity" :rules="rules" ref="entity">
        <!-- <el-form-item label="微信openid" label-width="120px">
          <el-input v-model="entity.openid" autocomplete="off" style="width: 80%"></el-input>
        </el-form-item> -->
        <el-form-item label="学生学号" label-width="120px" prop="username">
          <el-input
            v-model="entity.username"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="学生姓名" label-width="120px" prop="nickName">
          <el-input
            v-model="entity.nickName"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话号码" label-width="120px" prop="phone">
          <el-input
            v-model="entity.phone"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" label-width="120px" prop="sex">
          <el-radio v-model="entity.sex" :label="0">男</el-radio>
          <el-radio v-model="entity.sex" :label="1">女</el-radio>
        </el-form-item>
        <el-form-item label="状态" label-width="120px">
          <!-- <el-input v-model="entity.status" autocomplete="off" style="width: 80%"></el-input> -->
          <el-switch
            v-model="entity.status"
            active-text="已启用"
            inactive-text="已禁用"
            active-value="true"
            inactive-value="false"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="图片" label-width="120px">
          <el-upload
            action="http://localhost:9999/files/upload"
            :on-success="fileSuccessUpload"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save('entity')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../utils/request";
const url = "/api/student/";
const roleUrl = "/api/student/role/";
const exportUrl = "http://localhost:9999/api/student/"

export default {
  name: "Student",
  data() {
    return {
      fileList: [],
      options: [],
      tableChecked: [],
      text: "",
      user: {},
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      entity: {},
      total: 0,
      dialogFormVisible: false,
      search: {
        studentNum: "",
        studentName: "",
      },
      rules: {
        studentNum: [
          {
            pattern: /^[0-9]*$/,
            required: true,
            message: "请输入学生学号,类型为数字",
            trigger: "blur",
          },
          { len: 10, message: "学号长度为10", trigger: "blur" },
        ],
        studentName: [
          { required: true, message: "请输入学生姓名", trigger: "blur" },
          {
            min: 1,
            max: 4,
            message: "学生姓名长度为1到4个字符",
            trigger: "blur",
          },
          { whitespace: true, message: "只存在空格", trigger: "blur" },
        ],
        phone: [
          {
            pattern: /^[0-9]*$/,
            required: true,
            message: "请输入电话号码",
            trigger: "blur",
          },
          { len: 11, message: "电话号码长度为10", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请至少选择一个", trigger: "blur" }],
      },
    };
  },
  created() {
    this.user = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : {};
    this.load();
  },
  methods: {
    indexMethod(index) {
      return index + 1;
    },
    handleBatchDelete(val) {
      // console.log("val",val)
      this.tableChecked = [];
      val.forEach((element) => {
        this.tableChecked.push(element.id);
        // console.log("tableChecked",this.tableChecked)
      });
    },
    batchDelete() {
      this.$confirm('此操作将永久删除学生信息，是否继续？','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      var idsParams = {
        'ids': this.tableChecked,
      };
      API.post(url + "batchDelete", idsParams).then((res) => {
        if (res.code === "0") {
          this.load()
          this.$message({
          type: 'success',
          message: '删除成功!'
        });
        
        }
        if (res.code === "-1") {
          this.$message({
          type: 'error',
          message: '删除失败'
        });
        }
      });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    batchExport() {
      window.location.href=exportUrl + "exportStudentInfo";
    },
    batchImport() {
      // window.location.href=url + "/exportStudentInfo";
    },
    fileSuccessUpload(res) {
      this.entity.file = "http://localhost:9999/files/" + res.data;
      this.entity.img = "http://localhost:9999/files/" + res.data;
      this.fileList = [res.data];
      console.log("file.res", res);
    },
    changeRole(row) {
      this.entity = JSON.parse(JSON.stringify(row));
      API.put(url, this.entity).then((res) => {
        if (res.code === "0") {
          this.$message({
            type: "success",
            message: "操作成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
        this.load();
        this.dialogFormVisible = false;
      });
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.load();
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.load();
    },
    load() {
      API.post(
        url + "/page/" + this.pageNum + "/" + this.pageSize,
        this.search
      ).then((res) => {
        this.tableData = res.data.records || [];
        this.total = res.data.total;
      });

      API.get(roleUrl).then((res) => {
        this.options = res.data;
      });
    },
    add() {
      this.entity = {};
      this.fileList = [];
      this.dialogFormVisible = true;
    },
    edit(obj) {
      this.entity = JSON.parse(JSON.stringify(obj));
      this.fileList = [];
      this.dialogFormVisible = true;
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.entity.id) {
            API.post(url + "/addStudent", this.entity).then((res) => {
              if (res.code === "0") {
                this.$message({
                  type: "success",
                  message: "操作成功",
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.msg,
                });
              }
              this.load();
              this.dialogFormVisible = false;
            });
          } else {
            API.put(url + "/update", this.entity).then((res) => {
              if (res.code === "0") {
                this.$message({
                  type: "success",
                  message: "操作成功",
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.msg,
                });
              }
              this.load();
              this.dialogFormVisible = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    del(id) {
      API.delete(url + id).then((res) => {
        if (res.code === "0") {
            this.$message({
            type: "success",
            message: "操作成功",
        });
        this.load();
        }
        if (res.code === "-1") {
          this.$message({
            type: "error",
            message: "操作失败",
        });
        }
      });
    },
    
  },
};
</script>

<style scoped>
</style>